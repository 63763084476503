.ant-picker {
  @apply px-20 rounded-8 w-full h-50;
  border-color: var(--dc-card-background);
  background-color: var(--dc-card-background);

  //! Remove boxshadow
  &.ant-picker-focused{
    @apply shadow-none;
  }

  //! Input date styles
  .ant-picker-input input{
    @apply text-16;
    color: var(--dc-text-primary);
  }

  //! Placeholder styles
  .ant-picker-input input::placeholder{
    @apply transition opacity-0 duration-150;
    color: var(--dc-text-secondary);

  }

  //! When picker is hovered
  &:not(.ant-picker-disabled):hover{
    border-color: var(--dc-secondary);

    &+ nz-form-label label{
      // @apply text-gray-steel;
    }
  }

  &:not(.ant-picker-disabled):has(+ nz-form-label:hover) {
    // @apply border-dc-primary;

    & + nz-form-label label{
      // @apply text-gray-steel;
    }
  }

  //! When picker is focused
  &:not(.ant-picker-disabled):is(:focus, .ant-picker-focused, .has-value){
    .ant-picker-input input::placeholder{
      @apply opacity-100 delay-150 duration-150;
    }

    & + nz-form-label{
      @apply top-0 left-0 leading-15;
      background-color: var(--white);

      label{
        // @apply text-12 text-blue-sky leading-13;
      }
    }
  }

  //! When picker doesn't have label, just placeholder is shown
  &:not(.ant-picker-disabled):not(:has(+ nz-form-label)){
    .ant-picker-input input::placeholder{
      @apply opacity-100 duration-0;
    }
  }

  //! When picker has an error
  &.ant-picker-status-error.ant-picker, .ant-picker-status-error.ant-picker:not([disabled]):hover{
    border-color: var(--dc-danger) !important;
    background-color: var(--dc-card-background) !important;

  }

  &.ant-picker-status-error.ant-picker + nz-form-label label{
    color: var(--dc-danger);
    &:hover {
      color: var(--dc-danger);
    }
  }

  //! When picker is disabled
  &.ant-picker-disabled{
    // @apply border-gray-dark bg-gray-light;

    .ant-picker-input input{
      // @apply text-gray-dark;
    }
  }

  &.ant-picker-disabled + nz-form-label {
    @apply cursor-not-allowed;

    label{
      // @apply text-gray-dark cursor-not-allowed;
    }
  }

  //! When input is disabled and has value
  &.ant-picker-disabled:is(.has-value) + nz-form-label{
    @apply top-0 left-0 leading-15;
    background-color: var(--white);;

    label{
      @apply text-12 leading-13;
    }
  }

  //! Icons syles
  .ant-picker-clear{
    @apply border-none text-16 mt-[-0.1rem];
    color: var(--dc-text-primary);
    border-color: var(--white);
    background-color: var(--dc-card-background);

  }

  .ant-picker-suffix{
    color: var(--dc-text-secondary);
  }
}

//! Margin bottom in datepicker just in order to let some space between panel and label
.ant-picker-panel-container{
  @apply mb-8;
}
