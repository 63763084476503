//! Set input height
.ant-select-selection-search {
  @apply h-full block ;
}

.ant-select .ant-select-selection-search-input {
  @apply h-full px-10   text-16 leading-18 #{!important};
}

.ant-select-single:not(.ant-select-customize-input) {
  @apply w-full flex  relative;
  background-color: var(--transparent);
  .ant-select-selector {
    @apply h-50 max-h-50 w-full;
  }
}

.ant-select {
  @apply relative max-h-50 ;


}



.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  @apply h-50 w-full rounded-10 pl-20 pr-50;
  border-color: var(--dc-card-background);
  background-color: var(--dc-card-background);

  .ant-select-selection-placeholder {
    color: var(--dc-text-secondary);
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-selector  {
  border-color: var(--dc-secondary) !important;
  @apply border rounded-10 border-solid #{!important};
}

.ant-select .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  @apply leading-50 font-main-regular text-16;
  color: var(--dc-text-primary);
}

//? Select base styles
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  // @apply border-gray-dark rounded-8;
}

//? Select arrow and clear styles
.ant-select{
  .ant-select-clear{
    @apply -translate-y-[0.3rem] -translate-x-[1rem] text-16 mr-1;
    color: var(--dc-text-primary);
  }

  .ant-select-arrow{
    @apply transition duration-200 mr-1 min-w-50 h-50 grid place-items-center absolute right-0 top-[0.5rem] cursor-pointer z-[1];
    color: var(--dc-text-primary);
  }

  &.ant-select-focused.ant-select-open{
    .ant-select-arrow{
      @apply -rotate-180;
    }
  }
}

//! Remove the shadow decoration
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector, .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  @apply shadow-none;
}

.ant-select {
  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    // @apply text-gray-dark transition opacity-0 duration-150;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    // @apply border-blue-sky;
  }

  //! When select is hovered
  &:not(.ant-select-disabled):hover{

    .ant-select-selector{
      // @apply border-blue-sky;
      border-color: var(--dc-success);
    }

    &+ nz-form-label label{
      color: var(--dc-text-primary);
    }
  }

  &:not(.ant-select-disabled):has(+ nz-form-label:hover) {
    .ant-select-selector{
      // @apply border-blue-sky;
    }

    & + nz-form-label label{
      // @apply text-gray-steel;
    }
  }

  //! When select is focused
  &:not(.ant-select-disabled):is(:focus, .ant-select-focused, .has-value){
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
      @apply opacity-100 delay-150 duration-150;
    }

    & + nz-form-label{
      @apply top-0 left-0 leading-15;
      background-color: var(--white);

      label{
        // @apply text-12 text-blue-sky leading-13;
      }
    }
  }

  //! When select doesn't have label, just placeholder is shown
  &:not(.ant-select-disabled):not(:has(+ nz-form-label)){
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
      @apply opacity-100 duration-0;
    }
  }

  //! When select is focused and open
  &.ant-select-focused.ant-select-open .ant-select-selector{
    @apply rounded-b-[0] duration-75;
  }

  //! When select has a error
  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    background-color: var(--dc-card-background) !important;
    border-color: var(--dc-danger) !important;
    @apply border border-solid #{!important};
  }

  &.ant-select-status-error:not(.ant-select-disabled) + nz-form-label label{
    color: var(--dc-danger);

    &:hover {
      color: var(--dc-danger)
    }
  }

  //! When select is disabled
  &.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector{
    // @apply border-gray-dark bg-gray-light;

    .ant-select-selection-item{
      // @apply text-gray-dark;
    }
  }

  &.ant-select-disabled:not(.ant-select-customize-input) + nz-form-label{
    @apply cursor-not-allowed;

    label{
      // @apply text-gray-dark cursor-not-allowed;
    }
  }

  //! When input is disabled and has value
  &.ant-select-disabled:is(.has-value) + nz-form-label{
    @apply top-0 left-0 leading-15;
    background-color: var(--white);

    label{
      @apply text-12 leading-13;
    }
  }


}

//! Option container styles
.ant-select-dropdown{
  // @apply rounded-b-8 rounded-t-[0] border border-blue-sky mt-0 border-t-0;

  //! Option styles
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    // @apply bg-blue-frost font-Main-Bold;
  }
}
