.track-steps {
  .ant-steps-item  {
    @apply pb-10;
  }
  .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    @apply left-25 top-[0.6rem]  w-[0.2rem];

    &::after {
      @apply w-[0.2rem];
      background-color: var(--dc-card-background);
    }
  }
  .ant-steps-item .ant-steps-item-icon {
    @apply w-50 mr-10;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    @apply text-12 font-main-regular leading-14 pt-10;
    color: var(--dc-text-primary);
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    @apply text-18 leading-22 font-main-regular;
    color: var(--dc-text-primary);
  }
}
