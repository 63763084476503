:root {
  --white: #FFFFFF;
  --transparent: transparent;
  --dc-background: #FFFFFF;
  --dc-primary: #0088FF;
  --dc-secondary: #3F9389;
  --dc-text-primary: #000000;
  --dc-text-secondary: #6C757D;
  --dc-shadow-primary: #00000029;
  --dc-shadow-secondary: #00000033;
  --dc-card: #F2FBF9;
  --dc-card-background: #EFEFEF;
  --dc-card-background-secondary: #EEF8F7;
  --dc-card-background-disabled: #E6EAEC;
  --dc-danger: #FA4B53;
  --dc-success: #56BAB1;
  --success-check: #2FC66A;
  --gray-ligero: #D2D5DA;
  --gray-medio: #EBEDF4;
  --gray-claro: #F5F6FC;
}


/* Text colors */
.text-dc-background {
  color: var(--white);
}

.text-dc-primary {
  color: var(--dc-primary);
}

.text-dc-secondary {
  color: var(--dc-secondary);
}

.text-dc-text-primary {
  color: var(--dc-text-primary);
}

.text-dc-text-secondary {
  color: var(--dc-text-secondary);
}

.text-dc-danger {
  color: var(--dc-danger);
}

.text-dc-success {
  color: var(--dc-success);
}

.text-success-check {
  color: var(--success-check);
}

.text-gray-ligero {
  color: var(--gray-ligero);
}

.text-white {
  color: var(--white);
}

/* Background colors */
.bg-dc-background {
  background-color: var(--dc-background);
}

.bg-dc-primary {
  background-color: var(--dc-primary);
}

.bg-dc-secondary {
  background-color: var(--dc-secondary);
}

.bg-dc-text-secondary {
  background-color: var(--dc-text-secondary);
}

.bg-dc-card-background {
  background-color: var(--dc-card-background);
}

.bg-dc-card-background-secondary {
  background-color: var(--dc-card-background-secondary);
}

.bg-dc-danger {
  background-color: var(--dc-danger);
}

.bg-dc-success {
  background-color: var(--dc-success);
}

.bg-gray-medio {
  background-color: var(--gray-medio);
}

.bg-gray-claro {
  background-color: var(--gray-claro);
}

.bg-transparent {
  background-color: var(--transparent);
}

.bg-white {
  background-color: var(--white);
}

/* Border colors*/
.border-dc-text-secondary {
  border-color: var(--dc-text-secondary);
}

.border-dc-card-background {
  border-color: var(--dc-card-background);
}

.border-gray-ligero {
  border-color: var(--gray-ligero);
}

/* Special */
.bg-dc-card-background.bg-dc-primary {
  background-color: var(--dc-primary);
}

.bg-card {
  background-color: var(--dc-card);
}

.bg-card_border-left {
  background-color: var(--dc-secondary);
}

.home-logo {
  width: 20rem;
}

.end-banner {

}
