.swiper-btn {
  @apply top-[50%] w-30 aspect-square grid place-content-center z-10 absolute;
  transform: translateY(-10%);;
}

.swiper-button-disabled {
  @apply hidden #{!important};
}

::ng-deep {
.custom-slider {

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none !important;
  }
}
}
