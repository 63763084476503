.ant-btn{
  @apply min-h-50 rounded-40 font-main-medium px-13 py-5 text-18 shadow-button;

  .anticon{
    @apply align-[inherit];

    &.anticon-loading{
      @apply -translate-y-2;
    }
  }
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  @apply ml-13;
}

//? Primary button styles
.ant-btn-primary {
  background-color: var(--dc-success);
  border-color: var(--dc-success);
  color: var(--white);

}

.ant-btn-default {
  background-color: var(--dc-secondary);
  border-color: var(--dc-secondary);
  color: var(--white);

}

//! When button is focused or hovered
.ant-btn-primary:focus,
.ant-btn-primary:hover{
  @apply border;
  background-color: var(--white);
  border-color: var(--dc-success);
  color: var(--dc-success);

}

.ant-btn-default:focus,
.ant-btn-default:hover{
  @apply border;
  background-color: var(--white);
  border-color: var(--dc-secondary);
  color: var(--dc-secondary);

}

//! When button is disabled
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover,
.ant-btn-default[disabled],
.ant-btn-default[disabled]:active,
.ant-btn-default[disabled]:focus,
.ant-btn-default[disabled]:hover {
  @apply [text-shadow:none] shadow-none;
  background-color: var(--dc-text-secondary);
  border-color: var(--dc-text-secondary);
  color: var(--white);
}

.ant-btn-icon-only {
  @apply w-32 h-32 p-[0.24rem_0] text-16 align-[-0.3rem];
}

.ant-btn-circle {
  @apply min-w-32 px-0 rounded-[50%];
}
