//* Roboto
@font-face {
  font-family: Main Bold;
  src: url('../../assets/fonts/HelveticaNeueBold.otf');
}

@font-face {
  font-family: Main Medium;
  src: url('../../assets/fonts/HelveticaNeueMedium.otf');
}

@font-face {
  font-family: Main Regular;
  src: url('../../assets/fonts/HelveticaNeueRegular.ttf') format('truetype');
}
