.main-layout {
  @apply flex h-dvh justify-center  w-full mx-auto overflow-hidden;
  background-color: var(--white);
}

.main-home {
  @apply flex flex-col h-dvh w-full pb-20 pt-50 px-10 mobile:px-30 max-w-[43rem] mx-auto;

  .form-content  {
    @apply flex-1;
    max-height: calc(100% - 270px);

    .content-overflow {
      @apply flex flex-col  flex-1  overflow-y-auto overflow-x-hidden h-full;
      max-height: calc(100% - 150px);
    }
  }
}

.layout-page {
  @apply flex h-full pt-10 pb-20 px-10 mobile:px-30 max-w-[43rem] mx-auto;
  background-color: var(--white);

  main {
    @apply flex flex-col h-full w-full;
  }

  .main {
    @apply flex flex-col flex-1 overflow-y-auto;

    .content-overflow {
      @apply flex flex-col  flex-1  overflow-y-auto overflow-x-hidden h-full;
      max-height: calc(100% - 150px);

      &.custom-height {
        max-height: calc(100% - 30px);
      }
    }
  }

  &.rotate-page {
    @apply max-w-[50rem] px-20 w-full;
  }
}
