.ant-modal-mask{
  // @apply bg-gray-oscuro/50 backdrop-blur;
}

.ant-modal-content{
  @apply rounded-8 overflow-auto;

  .ant-modal-body{
    @apply p-0;
  }
}
