.ant-form-item {
  @apply  m-0 #{!important};

  .ant-form-item-label {
    @apply leading-15 p-0 #{!important};
    // @apply absolute overflow-visible top-[50%] translate-y-[-50%] left-3 pl-2 pr-[0.2rem] rounded-r-5 transition-all duration-200 leading-20;
    // @apply py-0 #{!important};

    label {
      @apply  block text-15 leading-[3.2rem] font-main-regular ml-10;
    }
  }
}

.ant-form-item-with-help .ant-form-item-explain {
  @apply min-h-[1.4rem] leading-14 mt-5 ml-10;
  .ant-form-item-explain-error {
    @apply text-11;
    color: var(--dc-danger);
  }
}

.ant-form-item-control-input {
  @apply min-h-0;
}

::ng-deep {
  nz-form-control .ant-form-item-control-input-content {
    @apply flex flex-col;

  }

}
