/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

//* NzComponents and Components Styles imports
@import './styles/components/nz-button.scss';
@import './styles/components/nz-checkbox.scss';
@import './styles/components/nz-datepicker.scss';
@import './styles/components/nz-form.scss';
@import './styles/components/nz-input.scss';
@import './styles/components/nz-layout.scss';
@import './styles/components/nz-modal.scss';
@import './styles/components/otp.scss';
@import './styles/components/pdf-viewer.scss';
@import './styles/components/nz-select.scss';
@import './styles/components/nz-slider.scss';
@import './styles/components/nz-spin.scss';
@import './styles/components/nz-steps.scss';
@import './styles/components/nz-upload.scss';

//* Fonts
@import './styles/fonts/fonts.scss';

//* Global Styles
html, body{
  font-family: 'Main Regular';
  scroll-behavior: smooth;
}

//* Scrollbar global style
::-webkit-scrollbar {
  height: 0.8rem;
  width: 0.8rem;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0rem 0rem 0.5rem var(--color-blue-transparent);
  border: 2px solid transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  box-shadow: inset 0rem 0rem 0.5rem transparent;
}

.hide-scroollbar{
  &::-webkit-scrollbar{
    display: none;
  }

  overflow: -moz-scrollbars-none; /* Other browser */
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* IE 11 */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.overlay-panel-snackbar {
  @apply w-full;
  transition: all 0.1s ease-in-out;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(-90deg);
  }
}

@keyframes bgRotate {
  0% {
      opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
      opacity: 0;
      visibility: hidden;
  }
}
.rotate {
  animation: rotate 1s forwards;
}

.bg-rotate {
  animation: bgRotate 5s forwards;
}
