.ant-checkbox-wrapper.custom-checkbox {
  @apply relative w-full ml-0 #{!important};

  .ant-checkbox {
    @apply w-18;
    //! Text Styles

    .ant-checkbox-input {
      @apply w-[1.5rem] max-w-[1.5rem] h-[1.5rem] cursor-pointer outline-0;
      border-color: var(--dc-text-secondary);
    }

    &+span {
      @apply absolute top-[-0.5rem] w-full p-0;
    }

  }

  .ant-checkbox-inner,
    .ant-checkbox-checked::after {
      @apply border-0 opacity-0 outline-0 #{!important};
    }

  .text-content {
    @apply flex w-full items-center h-21;
  }
   .text{
    @apply font-main-regular text-15 leading-15;
    color: var(--dc-text-primary);
  }

  &.ant-checkbox-checked::after {
    border-color: var(--dc-text-primary);
  }

  &.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--dc-text-primary);
    border-color: var(--dc-text-primary);
  }

  //! Ckeck styles
  &.ant-checkbox-checked .ant-checkbox-inner::after {
    @apply [transform:rotate(45deg)_scale(1)_translate(-55%,-55%)];
  }

  //! Remove halo animation
  &.ant-checkbox-checked::after{
    @apply animate-none;
  }

  //! When is disabled styles
  &.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: var(--dc-text-secondary) !important;
    border-color: var(--dc-text-secondary) !important;
  }

  &.ant-checkbox-disabled + span {
    color: var(--dc-text-secondary);
  }
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner{
  border-color: var(--dc-text-primary)
}

//! Errores
.ant-form-item-has-error {
  .ant-checkbox-wrapper.custom-checkbox .text-content ,
  .ant-checkbox-wrapper.custom-checkbox .text-content span{
    color: var(--dc-danger);

    // &.ng-dirty.ng-invalid{
    //   .ant-checkbox-inner{
    //     @apply border-dc-danger;
    //   }
    // }
  }
}


