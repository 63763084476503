otp-input {
  @apply flex gap-20 justify-center flex-wrap w-fit;

  input{
    @apply transition duration-200 w-50 h-50 text-center rounded-10 border text-20 font-main-regular;
    background-color: var(--white);
    border-color: var(--dc-card-background);
    color: var(--dc-text-primary);

    &:is(:focus, :hover, .ng-valid){
      @apply outline-0;
      border-color: var(--dc-success);
    }

    //!! When otp is disabled
    &:disabled {
      background-color: var(--dc-card-background-disabled);
      border-color: var(--dc-card-background-disabled);
      color: var(--dc-text-secondary);

    }
  }

  //! When otp has custom error with ngClass
  &.error{
    input{
      border-color: var(--dc-danger);
    }
  }

  //! When otp has error and is empty
  &.ng-dirty.ng-invalid:not(.has-value){
    input{
      border-color: var(--dc-danger);
    }
  }

}
