//? Input Styles
.ant-input{
  @apply rounded-10 h-50 text-16 px-20 leading-18 font-main-regular;
  color: var(--dc-text-primary);
  border-color: var(--transparent);
  background-color: var(--dc-card-background);;

  &::placeholder{
    @apply opacity-0 duration-150;
    color: var(--dc-text-secondary);
  }

  //! When input is disabled
  &.ant-input-disabled {
    color: var(--dc-text-secondary) !important;

    &+ nz-form-label label{
      @apply cursor-not-allowed;
      color: var(--dc-text-secondary);
    }

    &:hover, .ant-input[disabled]:hover{
      border-color: var(--dc-success);
    }
  }

  //! When input is hovered
  &:not(.ant-input-disabled):is(:hover, :focus, .ant-input-focused){
    // @apply border-dc-text-primary shadow-none;

    & + nz-form-label{
      @apply leading-20;
      label{
        // @apply text-gray-steel cursor-text leading-18;
      }
    }
  }

  //! When input is focused
  &:not(.ant-input-disabled):is(:focus, .ant-input-focused, .has-value){
    &::placeholder{
      @apply opacity-100 delay-150 duration-150;
    }

    & + nz-form-label{
      @apply top-0 left-0 leading-15;
      background-color: var(--bg-white);

      label{
        // @apply text-12 text-blue-sky leading-13;
      }
    }
  }

  //! When input is disabled and has value
  &.ant-input-disabled:is(.has-value) + nz-form-label{
    @apply top-0 left-0 leading-15;
    background-color: var(--bg-white);

    label{
      @apply text-12 leading-13;
    }
  }

  //! When input doesn't have label, just placeholder is shown
  &:not(.ant-input-disabled):not(:has(+ nz-form-label)){
    &::placeholder{
      @apply opacity-100 duration-0;
    }
  }

  &:hover {
    border-color: var(--dc-success);
  }
}

//! Input Error Styles
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus{
  @apply shadow-none;
  border-color: var(--dc-danger);

  & + nz-form-label label{
      color: var(--dc-danger);
    }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  @apply shadow-none border outline-0;
  background-color: var(--dc-card-background);
  border-color: var(--dc-danger) !important;

  & + nz-form-label label{
    color: var(--dc-danger);
  }
}

//? Input Group Styles
.ant-input-affix-wrapper{
  @apply rounded-10 h-50 p-0;
  background-color: var(--dc-card-background);
  border-color: var(--dc-card-background);

  .ant-input-prefix,
  .ant-input-suffix {
    @apply w-50 h-50 grid place-content-center m-0 rounded-10;
    background-color: var(--transparent);
  }

  .ant-input-prefix {
    @apply rounded-e-none;
  }

  .ant-input-suffix {
    @apply rounded-s-none;
  }

  & > .ant-input{
    @apply rounded-none h-auto;
    color: var(--dc-text-primary);


    &::placeholder{
      @apply opacity-100 delay-150 duration-150;
    }
  }

  //! When input is disabled
  &.ant-input-affix-wrapper-disabled {
    background-color: var(--dc-card-background-disabled);
    border-color: var(--dc-card-background-disabled);

    .ant-input-prefix,
    .ant-input-suffix {
    background-color: var(--transparent);
    color: var(--dc-text-secondary);
  }

    &+ nz-form-label label{
      // @apply cursor-not-allowed text-gray-dark;
    }
  }

  //! When input is hovered
  &:not(.ant-input-affix-wrapper-disabled):is(:hover, :focus, .ant-input-affix-wrapper-focused){
    @apply shadow-none;
    border-color: var(--dc-card-background-disabled);

    nz-form-label {
      label{
        // @apply text-gray-steel;
      }
    }
  }

  //! When input is focused
  &:not(.ant-input-affix-wrapper-disabled) > .ant-input:is( :focus, .ant-input-affix-wrapper-focused, .has-value){
      &::placeholder{
        @apply opacity-100 delay-150 duration-150;
      }

      &+ nz-form-label{
        @apply  top-0 -left-[0.1rem];
        background-color: var(--white);

        label{
          // @apply text-12 text-blue-sky;
        }
    }
  }

  //! When input is disabled and has value
  &.ant-input-affix-wrapper-disabled > .ant-input:is(.has-value) + nz-form-label{
    @apply top-0 -left-[0.1rem];
    background-color: var(--white);

    label{
      @apply text-12;
    }
  }
}

//! Input Group Error Styles
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus{
  @apply shadow-none;
  background-color: var(--dc-card-background);
  border-color: var(--dc-danger);

  nz-form-label label{
    color: var(--dc-danger);
  }
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  @apply shadow-none;
  border-color: var(--dc-danger);
  background-color: var(--dc-card-background);

  nz-form-label label{
    color: var(--dc-danger);
  }
}
