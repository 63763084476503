.ant-spin-nested-loading > div > .ant-spin{
  @apply max-h-[unset];
}

.ant-spin-container{
  @apply h-full w-full;
}

.ant-spin-spinning {
  @apply flex items-center justify-center #{!important};
}

.custom-spinner {
  .ant-spin-spinning {
    @apply flex items-center justify-center #{!important};
    background-color: var(--white) !important;
  }
  .ant-spin-blur {
    @apply opacity-100;
  }

  .ant-spin-text {
    color: var(--dc-success);
  }
}

.flex-spinner {
  .ant-spin-container{
    @apply flex flex-col;
  }
}

